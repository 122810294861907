(function($){
    var w = $(window).width();
    if (w > 1199) {
        let lastScroll = 0;
        window.addEventListener("scroll", () => {
            let currentScroll = window.pageYOffset;
            if (currentScroll - lastScroll > 0) {
                if (currentScroll > 50) {
                    $('header .nav-bar .logo img').width(90);
                }
            }
            lastScroll = currentScroll;
        });
    }

    $('.js-hamburger').click(function(){
        if ($('.mobile-links').hasClass('active')) {
            $('.mobile-links').removeClass('active');
        } else {
            $('.mobile-links').addClass('active');
        }
    });

    $('.js-sublinks').parent().hover(
        function() {
            $(this).find('.sublinks').css('display', 'flex');
        }, function() {
            $(this).find('.sublinks').css('display', 'none');
        }
    );

    $('.js-mobile-sublinks').click(function(){
        let grandParent =  $(this).parent().parent();
        if (grandParent.hasClass('active')) {
            grandParent.removeClass('active');
        } else {
            grandParent.addClass('active');
        }
    });

    $(() => {
        if ($('.testimonial-slider').length) {
            $('.testimonial-slider').slick({
                dots: true,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"></button>',
                nextArrow: '<button type="button" class="slick-next" aria-label="Next"></button>',
            });
        }

        if ($('.gallery-slider').length) {
            $('.gallery-slider').slick({
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"></button>',
                nextArrow: '<button type="button" class="slick-next" aria-label="Next"></button>',
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                    }
                ]
            });
        }
    });

    jQuery(document).on('gform_post_render', function(event, form_id, current_page){
        // Inline label forms: Add active class if input has value
        $('.js-gform input[type=text], .js-gform input[type=tel], .js-gform input[type=email], .js-gform input[type=number]').each(function () {
            if ($(this).val().length) {
                $(this).closest('.gfield').addClass('active');
            }
        });

        $('.js-gform select').on('change', function() {
            if ($(this).val() === "") {
                $(this).closest('.gfield').removeClass('active');
            } else {
                $(this).closest('.gfield').addClass('active');
            }
        });

        // Inline label forms: Add active class on focus
        $('.js-gform input[type=text], .js-gform input[type=tel], .js-gform input[type=email], .js-gform input[type=number]').on('focus', function () {
            $(this).closest('.gfield').addClass('active');
        });

        // Inline label forms: Remove active class on blur
        $('.js-gform input[type=text], .js-gform input[type=tel], .js-gform input[type=email], .js-gform input[type=number]').on('blur', function () {
            if (!$(this).val().length || $(this).val().includes('_')) {
                $(this).closest('.gfield').removeClass('active');
            }
        });
    });
})(jQuery);